import "../styles/global.css"
import { ThemeProvider } from 'styled-components';
import * as PropTypes from 'prop-types';
import { ScrollingProvider } from 'react-scroll-section';
import config from 'react-reveal/globals';
import * as React from "react"
import { defaultTheme } from "../styles/colors"

config({ ssrFadeout: true });

const Layout = ({ children }) => {
  return (
    <main>
      <ThemeProvider theme={defaultTheme}>
        <ScrollingProvider>
          {children}
        </ScrollingProvider>
      </ThemeProvider>
    </main>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
