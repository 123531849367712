export const defaultTheme = {
  colors: {
    background: '#FFFFFF',
    backgroundDark: '#f0e6f6',

    text: '#333333',

    primary: '#ffa900',
    primaryLight: '#ffc146',
    primaryDark: '#c28100',

    secondary: '#0b7d00',
    secondaryLight: '#27801e',
    secondaryDark: '#084400',
  }
};